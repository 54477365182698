import React, { useEffect, useMemo, useState } from "react";

import SurahCard from "../surahcard/SurahCard";
import surahsArray from "../../constants";
import "./raodmap.css";

const Roadmap = () => {
  const surahIds = useMemo(
    () => [
      1, 114, 113, 112, 111, 110, 109, 108, 107, 106, 105, 104, 103, 102, 101,
      100, 99, 98, 97, 96, 95, 94, 93, 92, 91, 90, 89, 88, 87, 86, 85, 84, 83,
      82, 81, 80, 79, 78,
    ],
    []
  );

  const [surahs, setSurahs] = useState([]);
  const initialCompletedSurahs = JSON.parse(
    localStorage.getItem("completedSurahs")
  ) || [1];
  // eslint-disable-next-line no-unused-vars
  const [completedSurahs, setCompletedSurahs] = useState(
    initialCompletedSurahs
  );

  // eslint-disable-next-line no-unused-vars
  const [surahProgress, setSurahProgress] = useState(() => {
    const storedProgress = localStorage.getItem("surahProgress");
    const initialProgress = {};

    if (storedProgress) {
      const parsedProgress = JSON.parse(storedProgress);

      surahIds.forEach((id) => {
        initialProgress[id] = parsedProgress[id] || 0;
      });
    } else {
      initialProgress[surahIds[0]] = 0;
    }
    return initialProgress;
  });

  useEffect(() => {
    setSurahs(surahsArray);
  }, []);

  useEffect(() => {
    localStorage.setItem("completedSurahs", JSON.stringify(completedSurahs));
    localStorage.setItem("surahProgress", JSON.stringify(surahProgress));
  }, [completedSurahs, surahProgress]);
  const isSurahCompleted = (surahId) => {
    return completedSurahs.includes(surahId);
  };

  return (
    <div className="roadmap">
      <nav>
        {surahs.length ? (
          <ul>
            {surahs.map((surah) => (
              <li key={surah.id}>
                <SurahCard
                  surah={surah}
                  isSurahCompleted={isSurahCompleted(surah.id)}
                />
              </li>
            ))}
          </ul>
        ) : (
          <p>
            <i>No Surahs found </i>
          </p>
        )}
      </nav>
    </div>
  );
};

export default Roadmap;
