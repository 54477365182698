import React, { useState } from "react";

const Configuration = () => {
  const [theme, setTheme] = useState("light");
  const [audioEdition, setAudioEdition] = useState("ar.abdurrahmaansudais");
  const [textSize, setTextSize] = useState("medium");

  const handleSave = () => {
    // Save settings to global state or local storage
    console.log({ theme, audioEdition, textSize });
  };

  return (
    <div>
      <h1>Konfiguration</h1>
      <label>
        Theme:
        <select value={theme} onChange={(e) => setTheme(e.target.value)}>
          <option value="light">Light</option>
          <option value="dark">Dark</option>
        </select>
      </label>
      <label>
        Audio Edition:
        <select
          value={audioEdition}
          onChange={(e) => setAudioEdition(e.target.value)}
        >
          <option value="ar.abdurrahmaansudais">Abdurrahman Sudais</option>
          {/* Add more options as needed */}
        </select>
      </label>
      <label>
        Textgröße:
        <select value={textSize} onChange={(e) => setTextSize(e.target.value)}>
          <option value="small">Small</option>
          <option value="medium">Medium</option>
          <option value="large">Large</option>
        </select>
      </label>
      <button onClick={handleSave}>Speichern</button>
    </div>
  );
};

export default Configuration;
