import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Ayah from "../ayah/Ayah";
import surahs from "../../constants";
import "./surah.css";

const Surah = () => {
  const params = useParams()
  const id = parseInt(params.id);
  const [textData, setTextData] = useState(null);
  const [audioData, setAudioData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log("surahId bla bla :", id);
  const [pointsAwarded, setPointsAwarded] = useState(() => {
    const storedPointsAwarded = localStorage.getItem("pointsAwarded");
    return storedPointsAwarded ? JSON.parse(storedPointsAwarded) : false;
  });
  console.log("pointsAwarded:", pointsAwarded);

  const [points, setPoints] = useState(() => {
    const storedPoints = localStorage.getItem("points");
    return storedPoints ? parseInt(storedPoints) : 0;
  });
  console.log("points:", points);
  //  const [progress, setProgress] = useState({})
  const [progress, setProgress] = useState(() => {
    const storedProgress = localStorage.getItem("surahProgress");
    const progress = storedProgress ? JSON.parse(storedProgress) : {};
    return progress[id] || 0;
  });
  console.log("progress:", progress);
  useEffect(() => {
    const fetchSurahData = async () => {
      try {
        const textResponse = await fetch(
          `https://api.alquran.cloud/v1/surah/${id}/quran-simple-clean`
        );

        const audioResponse = await fetch(
          `https://api.alquran.cloud/v1/surah/${id}/ar.alafasy`
        );

        if (!textResponse.ok || !audioResponse.ok) {
          throw new Error("Network response was not ok");
        }

        const textResult = await textResponse.json();
        const audioResult = await audioResponse.json();

        console.log("Text Data:", textResult.data);
        console.log("Audio Data:", audioResult.data);

        setTextData(textResult.data);
        setAudioData(audioResult.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchSurahData();
  }, [id]);

  useEffect(() => {
    const storedProgress = localStorage.getItem("surahProgress");
    const progressData = storedProgress ? JSON.parse(storedProgress) : {};
    progressData[id] = progress;
    localStorage.setItem("pointsAwarded", JSON.stringify(pointsAwarded));
    localStorage.setItem("surahProgress", JSON.stringify(progressData));
  }, [progress, id, pointsAwarded]);

  const handleAyahClick = (ayahNumber) => {
    setProgress((prevProgress) => {
      const newProgress = prevProgress + 1;
      if (newProgress === surahs.find((surah) => surah.id === id).ayahCount) {
        unlockNextSurah(id);
      }
      return newProgress;
    });
    setPoints((prevPoints) => {
      const newPoints = prevPoints + 1;
      localStorage.setItem("points", newPoints.toString());
      return newPoints;
    });

    setPointsAwarded((prevPointsAwarded) => ({
      ...prevPointsAwarded,
      [ayahNumber]: true,
    }));
  };
  const unlockNextSurah = (surahId) => {
    const currentSurahIndex = surahs.findIndex((surah) => surah.id === surahId);
    if (currentSurahIndex !== -1 && currentSurahIndex < surahs.length - 1) {
      const nextSurahId = surahs[currentSurahIndex + 1].id;
      const completedSurahs =
        JSON.parse(localStorage.getItem("completedSurahs")) || [];
      if (!completedSurahs.includes(nextSurahId)) {
        completedSurahs.push(nextSurahId);
        localStorage.setItem(
          "completedSurahs",
          JSON.stringify(completedSurahs)
        );
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  console.log("pointsAwarded from surah:", pointsAwarded);
  return (
    <div>
      <h1>Surah Data</h1>
      <p>Total Points: {points}</p>
      {textData && audioData && (
        <div>
          {textData.ayahs.map((ayah) => {
            const audioAyah = audioData.ayahs.find(
              (a) => a.numberInSurah === ayah.numberInSurah
            );

            return (
              <Ayah
                key={ayah.number}
                ayah={ayah}
                audioAyah={audioAyah}
                handleAyahClick={() => handleAyahClick(ayah.number)}
                pointsAwarded={pointsAwarded[ayah.number]}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Surah;
