import React from "react";
import { NavLink } from "react-router-dom";
import "./home.css";
const Home = () => {
  const title = "Quran Lernen";
  return (
    <div className="home-background">
      <h1>{title}</h1>
      <nav>
        <ul>
          <li>
            <NavLink to={`/roadmap`}>Roadmap</NavLink>
          </li>
          <li>
            <NavLink to={`/configuration`}>Konfiguration</NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Home;
