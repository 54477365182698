// SurahCard.js
import React from "react";
import { Link } from "react-router-dom";
import "./SurahCard.css";
const SurahCard = ({ surah, isSurahCompleted }) => {
  const completedSurah = isSurahCompleted;
  console.log(completedSurah);
  // eslint-disable-next-line no-unused-vars
  const handelSetBelohnung = {
    // console.log('ihr belohnung ist : ')
  };
  return (
    <Link to={`/surah/${surah.id}`}>
      <button
        className={`surah-card ${isSurahCompleted ? "active" : "inactive"}`}
        disabled={!isSurahCompleted}
      >
        <h6>surah-id:{surah.id}</h6>
        <h5>{surah.name}</h5>
        <p>Ayahs: {surah.ayahCount}</p>
        <div>Belonung festlegen</div>
      </button>
    </Link>
  );
};

export default SurahCard;
