import React, { useState } from "react";
import { Container } from "reactstrap";
import { getTokenOrRefresh } from "../../token_util";

import { ResultReason } from "microsoft-cognitiveservices-speech-sdk";

const speechsdk = require("microsoft-cognitiveservices-speech-sdk");

const Ayah = ({ ayah, audioAyah, handleAyahClick, pointsAwarded }) => {
  const [displayText, setDisplayText] = useState(
    "INITIALIZED: ready to test speech..."
  );
  const [similarityPercentage, setSimilarityPercentage] = useState(0);

  const handleRecordedBlob = async (text) => {
    try {
      const orginalText = ayah.text;
      const recordedText = text;

      const similarity = compareText(orginalText, recordedText);
      setSimilarityPercentage(similarity);

      if (similarity >= 85) {
        handleAyahClick(ayah.number);
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function sttFromMic() {
    const tokenObj = await getTokenOrRefresh();
    console.log(tokenObj);

    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
      tokenObj.authToken,
      tokenObj.region
    );

    speechConfig.speechRecognitionLanguage = "ar-EG";

    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();

    const recognizer = new speechsdk.SpeechRecognizer(
      speechConfig,
      audioConfig
    );

    recognizer.recognizeOnceAsync((result) => {
      if (result.reason === ResultReason.RecognizedSpeech) {
        setDisplayText(`RECOGNIZED: Text=${result.text}`);
        handleRecordedBlob(result.text);
      } else {
        setDisplayText(
          "ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly."
        );
      }
    });
  }

  const compareText = (originalText, recognizedText) => {
    const levenshteinDistance = calculateLevenshteinDistance(
      originalText,
      recognizedText
    );
    const maxLength = Math.max(originalText.length, recognizedText.length);
    const stringSimilarity =
      ((maxLength - levenshteinDistance) / maxLength) * 100;
    return stringSimilarity;
  };

  const calculateLevenshteinDistance = (s1, s2) => {

    const dp = Array(s1.length + 1).fill(null).map(() => Array(s2.length + 1).fill(null));
    for (let i = 0; i <= s1.length; i++) dp[i][0] = i;
    for (let j = 0; j <= s2.length; j++) dp[0][j] = j;
    for (let i = 1; i <= s1.length; i++) {
        for (let j = 1; j <= s2.length; j++) {
            const cost = s1[i - 1] === s2[j - 1] ? 0 : 1;
            dp[i][j] = Math.min(
                dp[i - 1][j] + 1,     // Deletion
                dp[i][j - 1] + 1,     // Insertion
                dp[i - 1][j - 1] + cost  // Substitution
            );
        }
    }
    return dp[s1.length][s2.length];
};

  const removeDiacritics = (text) => {
    const diacriticsMap = {
      "َ": "",
      "ُ": "",
      "ِ": "",
      "ّ": "",
      "ً": "",
      "ٌ": "",
      "ٍ": "",
      "ْ": "",
      "ٰ": "",
      "ۡ": "",
      "ۢ": "",
      "ۣ": "",
      "ۤ": "",
      ۥ: "",
      ۦ: "",
      "ۧ": "",
      "ۨ": "",
      "۩": "",
    };
    return text.replace(
      /[ًٌٍَُِّْٰۣۡۢۤۥۦۧۨ۩]/g,
      (match) => diacriticsMap[match]
    );
  };

  console.log("pointsAwarded from Ayah:", pointsAwarded);

  return (
    <Container className="app-container">
      <div key={ayah.number} style={{ marginBottom: "20px" }}>
        <p>{ayah.text}</p>
        {audioAyah ? (
          <audio controls>
            <source src={audioAyah.audio} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
        ) : (
          <p>Audio not available</p>
        )}
        <div className="row main-container">
          <i
            className="fas fa-microphone fa-lg mr-2"
            onClick={() => sttFromMic()}
          ></i>
          {displayText && (
            <div>
              <p>Recognition Result:</p>
              <p>{displayText}</p>
              <p>Similarity: {similarityPercentage.toFixed(2)}%</p>
              {pointsAwarded ? (
                <p>Points awarded: Yes</p>
              ) : (
                <p>Points awarded: No</p>
              )}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Ayah;
