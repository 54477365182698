import React from "react";
import { Route, Routes } from 'react-router-dom';
import Configuration from "./component/configuration/Configuration";
import Home from "./component/home/Home";
import Roadmap from "./component/raodmap/Roadmap";
import Surah from "./component/surah/Surah";
import "./custom.css";
export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="roadmap" element={ <Roadmap /> } />
        <Route path="surah/:id" element={ <Surah /> } />
        <Route path="configuration" element={ <Configuration /> } />
      </Routes>
    </div>
  );
}
