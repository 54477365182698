const surahsArray = [
  { id: 1, name: "Al-Fatiha", ayahCount: 7 },
  { id: 114, name: "An-Nas", ayahCount: 6 },
  { id: 113, name: "Al-Falaq", ayahCount: 5 },
  { id: 112, name: "Al-Ikhlas", ayahCount: 4 },
  { id: 111, name: "Al-Masad", ayahCount: 5 },
  { id: 110, name: "An-Nasr", ayahCount: 3 },
  { id: 109, name: "Al-Kafirun", ayahCount: 6 },
  { id: 108, name: "Al-Kawthar", ayahCount: 3 },
  { id: 107, name: "Al-Ma'un", ayahCount: 7 },
  { id: 106, name: "Quraysh", ayahCount: 4 },
  { id: 105, name: "Al-Fil", ayahCount: 5 },
  { id: 104, name: "Al-Humazah", ayahCount: 9 },
  { id: 103, name: "Al-Asr", ayahCount: 3 },
  { id: 102, name: "At-Takathur", ayahCount: 8 },
  { id: 101, name: "Al-Qari'ah", ayahCount: 11 },
  { id: 100, name: "Al-Adiyat", ayahCount: 11 },
  { id: 99, name: "Az-Zalzalah", ayahCount: 8 },
  { id: 98, name: "Al-Bayyinah", ayahCount: 8 },
  { id: 97, name: "Al-Qadr", ayahCount: 5 },
  { id: 96, name: "Al-Alaq", ayahCount: 19 },
  { id: 95, name: "At-Tin", ayahCount: 8 },
  { id: 94, name: "Ash-Sharh", ayahCount: 8 },
  { id: 93, name: "Ad-Duha", ayahCount: 11 },
  { id: 92, name: "Al-Lail", ayahCount: 21 },
  { id: 91, name: "Ash-Shams", ayahCount: 15 },
  { id: 90, name: "Al-Balad", ayahCount: 20 },
  { id: 89, name: "Al-Fajr", ayahCount: 30 },
  { id: 88, name: "Al-Ghashiyah", ayahCount: 26 },
  { id: 87, name: "Al-A'la", ayahCount: 19 },
  { id: 86, name: "At-Tariq", ayahCount: 17 },
  { id: 85, name: "Al-Buruj", ayahCount: 22 },
  { id: 84, name: "Al-Inshiqaq", ayahCount: 25 },
  { id: 83, name: "Al-Mutaffifin", ayahCount: 36 },
  { id: 82, name: "Al-Infitar", ayahCount: 19 },
  { id: 81, name: "At-Takwir", ayahCount: 29 },
  { id: 80, name: "Abasa", ayahCount: 42 },
  { id: 79, name: "An-Nazi'at", ayahCount: 46 },
  { id: 78, name: "An-Naba'", ayahCount: 40 },
];

export default surahsArray;
